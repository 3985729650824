import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { address, currency, email, name, phone, whatsapp_phone } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import ResellerPartner from "../Assets/undraw_collaborators.svg";
import { toMoney } from "../Components/core";
  
interface FormData {
    firstname: string;
    lastname: string;
    phone_number: string;
    email: string;
    date_of_birth: string;
    country: string;
    address: string;
    tiktok: string;
    instagram: string;
    youtube: string;
    twitter: string;
    referral_id: string;
    message: string;
  }

const initialFormData = {
    firstname: '',
    lastname: '',
    phone_number: '',
    email: '',
    date_of_birth: '',
    country: '',
    address: '',
    tiktok: '',
    instagram: '',
    youtube: '',
    twitter: '',
    referral_id: '',
    message: '',
  };
  
class Reseller extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    formData: initialFormData,
    isLoading: false,
    successMessage: '',
    error: '',
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}

setFormData = (name, value) => {
let prevFormData = this?.state!['formData'];
let newFormData = {
    ...prevFormData,
    [name]: value,
}

  this.setState({['formData']: newFormData});
}

setDefFormData = (defData) => {
  this.setState({['formData']: defData});
}

setSuccessMessage = (message) => {
    this.setState({['successMessage']: message});
}

setError = (message) => {
    this.setState({['error']: message});
}

setIsLoading = (message) => {
    this.setState({['isLoading']: message});
}

handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    this.setFormData(name, value);
};


handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setIsLoading(true);
    
    try {
      const response = await fetch('https://qliqc.com/api/reseller', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this?.state!['formData']),
      });
     
      const data = await response.json();
      //console.log(data);
      if (data.status==true) {
        this.setSuccessMessage('Form submitted successfully!');
        this.setError('');
        this.setDefFormData(initialFormData);
        this.setIsLoading(false);
      } else {
        this.setError(data.message);
        this.setSuccessMessage('');
        this.setIsLoading(false);
      }
    } catch (error) {
        this.setError('An error occurred. Please try again later.');
        this.setSuccessMessage('');
        this.setIsLoading(false);
    }
  };

render(): ReactNode {
    return(
<>
<Header classX="contain" title="Become a Reseller"/>

<section className="partner-section">

<div className="white-d-bg _min">
<div className="container">
<div className="grid-2-for-exp">

<div className="gt-row col-2">
<div className="exp-img-text">
<h1>{name} Reseller Marketing</h1>
<p>Join Our Team as a {name} Marketer
Are you a passionate marketer looking for an exciting opportunity to earn performance-based income?
<br/>
Join us at {name} and be a part of the future of digital business cards. 
<br/><br/>
Our compensation structure is designed to reward your efforts and success with competitive commissions and attractive bonuses.
<br/>
Compensation Method: Performance-based Compensation and Bonus Structure
<br/><br/>

<div>
<NavLink to="#become-reseller" href-target="#become-reseller" href-target-type="self">
<button className="pg-btn">
 Get Started <i className="fa fa-arrow-right hidden"></i>
 </button>
</NavLink>
</div>
</p>
</div>
<div className="exp-img-image">
<img src={ResellerPartner} alt="Image" />
</div>
</div>
</div>
</div>
			
	</div>
    </section>

    <div className="grey-d-bg">
<div className="container">

<div className="list-ic-just-rw">
<div className="gt-row col-4">
<div>
<div className="icon-ic">
<i className="fa fa-gift"></i>
</div>
<h2>Commission Only</h2>
<p>Earn a commission on each sale you make.
Commission rate: 10-15% of the profit per card sold.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-certificate"></i>
</div>
<h2>Bonus Structure</h2>
<p>Receive bonuses for achieving specific sales targets.
Monthly and quarterly bonuses for reaching designated milestones.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-globe"></i>
</div>
<h2>Global Brands</h2>
<p>Our strategic partnerships with global brands like ours ensures that {name} consistently has the full support to produce and keep our resellers in business.</p>
</div>


<div>
<div className="icon-ic">
<i className="fa fa-dollar"></i>
</div>
<h2>Good Pricing</h2>
<p>Order a minimum of 50 pieces across our range of products at 10% off each product.</p>
</div>


</div>  
<br/>
<h4 className="hiddens" style={{padding: '5px 12px', background: '#145388', color: '#fff', margin: '0px'}}>Bonus Structure Example Calculation</h4>

<div className="bg-white">
<h4 className="hidden" style={{padding: '5px 12px', background: '#145388', color: '#fff', margin: '0px'}}>Bonus Structure Example Calculation</h4>

<div className="gt-row col" style={{lineHeight: '1.8'}}>
<div className="bonus-calc">
If you sell 100 white cards and 50 black cards in a month:
<br/>
White Card Sales:
<br/>
Revenue: 100 x {toMoney(20000, 0)}  = {toMoney(2000000, 0)}<br/>
Processing Cost: 100 x {toMoney(80000, 0)} = {toMoney(800000, 0)}<br/>
Profit: {toMoney(2000000, 0)} - {toMoney(800000, 0)} = {toMoney(1200000, 0)}<br/>
Black Card Sales:
<br/>
Revenue: 50 x {toMoney(25000, 0)} = {toMoney(1250000, 0)}<br/>
Processing Cost: 50 x {toMoney(10000, 0)} = {toMoney(500000, 0)}<br/>
Profit: {toMoney(1250000, 0)} - {toMoney(500000, 0)} = {toMoney(750000, 0)}<br/>
Total Profit:
<br/>
{toMoney(1200000, 0)} (white cards) + {toMoney(750000, 0)} (black cards) = {toMoney(1950000, 0)}<br/>
Commission (10%):<br/>

10% of {toMoney(1950000, 0)} = {toMoney(195000, 0)}
</div>  
</div>
</div>
</div>
</div>
</div>

<section className="contact-section" id="become-reseller">
<div className="container">
<form onSubmit={this.handleSubmit}>	
			<div className="contact-form clearfix">

            <h1>Ready to Join?</h1>
            <h3>Fill out the application form below and start your journey with {name} today!</h3>

<div className="gt-row col-2" style={{gridGap: '30px'}}>

<div className="sec2contactform">
<h3 className="sec2frmtitle">Get started below</h3>

{this?.state!['error'] && <div style={{ color: 'red' }}>{this?.state!['error']}</div>}
      {this?.state!['successMessage'] && <div style={{ color: 'green' }}>{this?.state!['successMessage']}</div>}
<div className="clearfix">
<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="firstname" placeholder="First Name" value={this?.state!['formData'].firstname} onChange={this.handleChange} required/>
</div>
<input className="inp" type="text" name="lastname" placeholder="Last Name" value={this?.state!['formData'].lastname} onChange={this.handleChange} required/>
<div>
</div>
</div>

<div className="gt-row col-2">
<div>
<input  className="inp" type="Email" name="email" placeholder="Email Address" value={this?.state!['formData'].email} onChange={this.handleChange} required/>
</div>

<div>
<input className="inp" type="tel" name="phone_number" placeholder="Phone Number" value={this?.state!['formData'].phone_number} onChange={this.handleChange} required/>   
</div>
</div>

<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="country" placeholder="Country" value={this?.state!['formData'].country} onChange={this.handleChange} required/>   
</div>

<div>
<input className="inp" type="text" name="address" placeholder="Address" value={this?.state!['formData'].address} onChange={this.handleChange} required/>   
</div>
</div>

<div className="gt-row col-2 pt10">
<div>
<label>Date of Birth</label>
<input  className="inp" type="date" name="date_of_birth" placeholder="Date of Birth" value={this?.state!['formData'].date_of_birth} onChange={this.handleChange} required/>
</div>

<div>
<label>Referral ID</label>
<input className="inp" type="text" name="referral_id" placeholder="Referral ID" value={this?.state!['formData'].referral_id} onChange={this.handleChange} />   
</div>
</div>
    
</div>

</div>


<div className="sec2contactform">
    <div className="hideMobile" style={{marginTop: '15px'}}>
<br/>

    <h3 className="sec2frmtitle hiddens"></h3>
 </div>
    <div className="sec2innercont">
    
<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="tiktok" placeholder="TikTok Profile link" value={this?.state!['formData'].tiktok} onChange={this.handleChange} />
</div>
<input className="inp" type="text" name="instagram" placeholder="Instagram Profile link" value={this?.state!['formData'].instagram} onChange={this.handleChange} />
<div>
</div>
</div>

<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="youtube" placeholder="Youtube Profile link" value={this?.state!['formData'].youtube} onChange={this.handleChange} />
</div>
<input className="inp" type="text" name="twitter" placeholder="Twitter Profile link" value={this?.state!['formData'].twitter} onChange={this.handleChange} />
<div>
</div>
</div>

<div className="clearfix pt0">
    <textarea name="message" id="" cols={30} rows={7} placeholder={"Why do you want to be a "+name+" Reseller..."} onChange={this.handleChange} required>{this?.state!['formData'].message}</textarea>
</div>

</div>
   
</div>
</div>

<br/>
<div className="clearfix">
  
  
  <button className="btn" name="send_message" type="submit" disabled={this?.state!['isLoading']}>
        {this?.state!['isLoading'] ? (
          <span>
            Submiting... <i className="fa fa-spinner fa-spin"></i>
          </span>
        ) : (
          <span>Submit</span>
         
        )}
      </button>
  
  </div>
</div>
</form>
</div>
</section>

<Footer />
</>
    )
}
}

export default Reseller;
