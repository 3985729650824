import { Helmet } from "react-helmet";
import { name, year, appUrl, primary_color, email, FB_AppID, twitterUsername, description, title } from "../Constant";
import FavIcon from "../Assets/favicon.png";
import LogoIcon from "../Assets/logo.png";

function Head(props : any){
    var titleX = props.title && props.title != "" ? props.title + " - " + title : title;
    var ogTitle = props.ogtitle && props.ogtitle != "" ? props.ogtitle + " - " + title : title;
    var ogUrl = props.ogurl && props.ogurl != "" ? props.ogurl : appUrl;
    var ogDesc = props.ogdesc && props.ogdesc != "" ? props.ogdesc : name+" - "+description;
    var ogImage = props.ogimage && props.ogimage != "" ? props.ogimage : appUrl+"/logo.png";

    return(
    <div>
      <Helmet>
    <title>{titleX}</title>
    {/*<base target="_blank" href={appUrl} />*/}
    <meta property="og:type" content="article" />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDesc} />
    <meta property="og:url" content={ogUrl} />
    <meta property="og:image" content={FavIcon} />
    <meta property="og:site_name" content={name} />
    <meta name="twitter:card" content={FavIcon} />
    <meta name="twitter:image:alt" content={ogImage} />
    <meta name="twitter:site" content={"@"+twitterUsername} />
    <meta property="fb:app_id" content={FB_AppID} />
    <meta name="keywords" content={name+" - "+description+", "+name} /> 
    <meta name="description" content={ogDesc} />
    <meta  content="30" />
    <meta name="subject" content="Script, Manuscript, Publishers, Scholars" />
    <meta name="copyright" content={name} />
    <meta name="language" content="EN" />
    <meta name="abstract" content="" />
    <meta name="topic" content="" />
    <meta name="summary" content="" />
    <meta name="Classification" content="Business" />
    <meta name="author" content={name + ", " +email} />
    <meta name="designer" content={name} />
    <meta name="copyright" content={name} />
    <meta name="reply-to" content={email} />
    <meta name="owner" content={name} />
    <meta name="url" content={appUrl} />
    <meta name="identifier-URL" content={appUrl} />
    <meta name="directory" content="submission" />
    <meta name="category" content="Publishing and Scholarly" />
    <meta name="coverage" content="Worldwide" />
    <meta name="distribution" content="Global" />
    <meta name="rating" content="General" />
    <meta name="revisit-after" content="7 days" />
    <meta http-equiv="Expires" content="0" />
    <meta http-equiv="Pragma" content="no-cache" />
    <meta http-equiv="Cache-Control" content="no-cache" />
     {/*Chrome, Firefox OS and Opera*/}
    <meta name="theme-color" content={primary_color} />
    <meta name="msapplication-navbutton-color" content={primary_color} />
    <meta name="apple-mobile-web-app-status-bar-style" content={primary_color} />
    <link rel="shortcut icon" href={FavIcon} type="image/png" />
    <link rel="icon" href={FavIcon} type="image/png"  />
    <link rel="canonical" href={appUrl} />
    <link rel="apple-touch-icon" href={FavIcon} type="image/png" />
    <link rel="apple-touch-icon" sizes="120x120" href={FavIcon} type="image/png" />
    <link rel="apple-touch-icon" sizes="152x152" href={FavIcon} type="image/png" />
    <link rel="apple-touch-icon" sizes="167x167" href={FavIcon} type="image/png" />
    <link rel="apple-touch-icon" sizes="180x180" href={FavIcon} type="image/png" />
    <link rel="alternate" href={appUrl} hrefLang="EN" />

    <script type="text/javascript">
    
    {`
    
    window.$crisp=[];window.CRISP_WEBSITE_ID="67213270-f038-4ba4-904a-9079dc0462c0";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();

    $("input[type=text], input, select, textarea").mouseover(zoomDisable).mousedown(zoomEnable);
    function zoomDisable(){
    $('head meta[name=viewport]').remove();
    $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />');
    }
    function zoomEnable(){
    $('head meta[name=viewport]').remove();
    $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />');
    }

     $(function(){

      function ScrollToTop() {
        window.scrollTo(0, 0);
        $(document).find('body').scrollTop(0, 0);
      }

      $(function(){
      $(document, window).on('click', '.scroll-top', function(e){
      e.preventDefault();
      });
      });

      $("input[type=text], input, select, textarea").mouseover(zoomDisable).mousedown(zoomEnable);
      function zoomDisable(){
      $('head meta[name=viewport]').remove();
      $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />');
      }
      function zoomEnable(){
      $('head meta[name=viewport]').remove();
      $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />');
      }

        $('a').each(function(e){
        if(window.location.hash == $(this).attr('href-target')){
            //alert($(this).attr('href-target'))
            $('html, body').animate({
            scrollTop: $($(this).attr('href-target'))?.offset()?.top
        }, 2000);
     
       }
      });

       $(document, window).on('click', 'a:not(.scroll-top)', function(e) {
        if($(this).attr('href-target') != undefined && $(this).attr('href-target') != ''){
           // alert("Hi")
        if(window.location.pathname == '/'){
            $('html, body').animate({
                scrollTop: $($(this).attr('href-target'))?.offset()?.top
           }, 2000);
        }
        else if($(this).attr('href-target-type') == 'self'){
        $('html, body').animate({
                scrollTop: $($(this).attr('href-target'))?.offset()?.top
           }, 2000);
        }
        else {
            window.location.href = "//"+window.location.host+"/"+$(this).attr('href-target');
       }
    }
       });
        
       //$('.nav-left-nav a.home, .mob-nav a.home').removeClass('active');

        $('.nav-left-nav a, .mob-nav a').each(function(e){
           if($(this).attr('href')?.indexOf("#") >= 0 && window.location.hash != $(this).attr('href-target') && window.location.hash != ""){
            $(this).removeClass('active');
           }

           if($(this).attr('href')?.indexOf("#") >= 0 && window.location.hash == $(this).attr('href-target') && window.location.hash != ""){
            $('.nav-left-nav a.home, .mob-nav a.home').removeClass('active');
           }
          });

          $(document, window).on('click', 'a:not(.scroll-top)', function(e) {
          $('.nav-left-nav a, .mob-nav a').each(function(e){
            //alert("Hi")
            if($(this).attr('href')?.indexOf("#") >= 0 && window.location.hash != $(this).attr('href-target') && window.location.hash != ""){
             $(this).removeClass('active');
            }
           });
        });
    });
    
   
   /*  Start the App JS/TS Here */

    $(function(){
  
      $(document, window).find('a').on('click', function(e){
      //alert("hi")
      //ScrollToTop();
      window.scrollTo(0, 0);
      });
    });


    $(function(){
      $(window, document).on('click', '.disabled', function(e){
      e.preventDefault();
      });
      });
    
        $(function(){
    
        $(document, window).find('a').on('click', function(e){
        //ScrollToTop();
        window.scrollTo(0, 0);
        });
        });
    
         $(function() {
          $('.disabled').each(function() {
          $(this).prop('title', "Disabled and Unavailable for Click Option");
          });
    
          $(document, window).on('click', '.popup-demo-video', function(e) {
          e.preventDefault();
          });
        
          $('.popup-demo-video').magnificPopup({
          type: 'iframe'
          });

          });
    `}
    </script>
    </Helmet>

    </div>
    );
    }

export default Head;