import React, { useState } from 'react';

interface FormData {
    firstname: string;
    lastname: string;
    phone_number: string
    email: string;
    message: string;
  }

const ContactForm: React.FC = () => {
  const initialFormData = {
    firstname: '',
    lastname: '',
    phone_number: '',
    email: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await fetch('https://qliqc.com/api/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
     
      const data = await response.json();
      //console.log(data);
      if (data.status==true) {
        setSuccessMessage('Form submitted successfully!');
        setError('');
        setFormData(initialFormData);
        setIsLoading(false);
      } else {
        setError(data.message);
        setSuccessMessage('');
        setIsLoading(false);
      }
    } catch (error) {
        setError('An error occurred. Please try again later.');
        setSuccessMessage('');
        setIsLoading(false);
    }
  };

  return (

<form onSubmit={handleSubmit}>
{error && <div style={{ color: 'red' }}>{error}</div>}
      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
<div className="clearfix">
<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="firstname" placeholder="First Name" value={formData.firstname} onChange={handleChange} required/>
</div>
<input className="inp" type="text" name="lastname" placeholder="Last Name" value={formData.lastname} onChange={handleChange} required/>
<div>
    
</div>
</div>

<div className="gt-row col-2">
<div>
<input  className="inp" type="Email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required/>
</div>

<div>
<input className="inp" type="tel" name="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={handleChange} required/>   
</div>
</div>
    
</div>

<div className="clearfix pt10">
    <textarea name="message" id="" cols={30} rows={7} placeholder="Your message here..." value={formData.message} onChange={handleChange} required></textarea>
</div>
<br/>
<div className="clearfix">
  
  
  <button className="btn" name="send_message" type="submit" disabled={isLoading}>
        {isLoading ? (
          <span>
            Sending... <i className="fa fa-spinner fa-spin"></i>
          </span>
        ) : (
          <span>Send Message</span>
         
        )}
      </button>
  
  </div>
</form>
  );
};

export default ContactForm;
