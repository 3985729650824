import React, { Fragment, ReactNode } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import logo from './logo.svg';
import './Css/App.css';
import './Css/App.scss';
import Index from './Pages/Index';
import NotFound from './Pages/NotFound';
import Pricing from './Pages/pricing';
import Shop from './Pages/shop';
import Contact from './Pages/contact';
import Features from './Pages/features';
import Reseller from './Pages/reseller';
import Ambassador from './Pages/ambassador';


class App extends React.Component <any> {

constructor(props){
super(props);
this.state = {
message: '',
}
}

componentDidMount(){

}

render(): ReactNode {
  return (
    <>
    <div className="App">
    <BrowserRouter>
    <Fragment>
    <Routes>
    <Route path="/" element={<Index />}></Route>
    <Route path="/home" element={<Index />}></Route>
    <Route path="/index" element={<Index />}></Route>
    <Route path="/404" element={<NotFound />}></Route>
    <Route path="/pricing" element={<Pricing />}></Route>
    <Route path="/shop" element={<Shop />}></Route>
    <Route path="/contact" element={<Contact />}></Route>
    <Route path="/features" element={<Features />}></Route>
    <Route path="/reseller" element={<Reseller />}></Route>
    <Route path="/ambassador" element={<Ambassador />}></Route>


    <Route path="*" element={<NotFound />}></Route>
        </Routes>
        </Fragment>
   </BrowserRouter>
   </div>
    </>
   );
  }
}

export default App;
