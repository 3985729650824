import React, { useState, useEffect } from 'react';
import {web_register_url } from "../Constant";

interface Plan {
  id: number;
  name: string;
  price: string;
  duration: string;
  themes: string;
  business: string;
  max_users: string;
  enable_custdomain: string;
  enable_custsubdomain: string;
  enable_branding: string;
  pwa_business: string;
  enable_qr_code: string;
  user_message: string;
  subtitle: string;
  popular: string;
}

const PlanApi: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://qliqc.com/api/getplanall');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: { data: Plan[] } = await response.json();

      setPlans(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  return (
    <div className="gt-row col-3">
      {loading ? (
        <div className="loading-plan">
          Loading Plans...
        </div>
      ) : (
        plans.map((plan) => (
            <div className="pricing-block wow animated animatedFadeInUp fadeInUp">
                {plan.popular && (
                     <div className="top-lb-w-1">
                     <div className="top-lb-w-2">
                     <div className="top-lb">Most Popular</div>
                     </div>
                     </div>
                )}
            <div className="inner-box">
                <div className="icon-box hidden">
                    <div className="icon-outer"><i className="fas fa-paper-plane"></i></div>
                </div>
                <div className="price-box">
                    <div className="title"> {plan.name}</div>
                    <div className="subtitle">{plan.subtitle}</div>
                    <div className="price">{plan.price}<sub>{plan.duration}</sub></div>
                </div>
                <ul className="features">
                    <li className="true">Unlimited sharing</li>
                    <li className="true">Unlimited links on your page</li>
                    <li className="true">{plan.business} digital business profile</li>
                    <li className="true">Basic customizations</li>
                    <li className="true">{plan.themes} Qliq Card Themes</li>
                    <li className="true">{plan.max_users} users</li>
                    <li className={plan.enable_branding}>Enable Branding</li>
                    <li className="true">Remove Qliq Card logo</li>
                    <li className={plan.enable_custsubdomain}>Enable Sub-Domain</li>
                    <li className={plan.enable_custdomain}>Enable Domain</li>
                    <li className={plan.enable_qr_code}>Enable QR Code</li>
                    <li className={plan.pwa_business}>Enable Progressive App</li>
                </ul>
                <div className="btn-box">
                <a href={web_register_url} className="theme-btn">Register</a>
                <div className="lite-dt">{plan.user_message}</div>
                </div>
            </div>
        </div>
        ))
      )}
    </div>
  );
};


export default PlanApi;
