import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import HeroIMG from "../Assets/nfc-card-phone.png";
import HeroIMG2 from "../Assets/alldashboard.png";
import { address, currency, email, name, phone, whatsapp_phone } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import ContactForm from '../Components/contactform';


  

class Contact extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}

render(): ReactNode {
    return(
<>
<Header classX="contain" title="Plans & Pricing"/>

<section className="contact-section">
<div className="container">
		
			<div className="contact-form clearfix">

            <h1>Contact Us</h1>
            <h3>Reach out to us, either you want to make enquiry, or you want to request a demo, or for partnership.</h3>

            <div className="gt-row col-2">

				<div className="contact-details">
					<div className="sec2innercont">
						<div className="sec2addr">
							<p><span className="collig">Address:</span> {address!['address']}.</p>
							<p><span className="collig">Phone:</span> {phone}</p>
							<p><span className="collig">Email:</span> {email}</p>
							<p><span className="collig">WhatsApp:</span> {whatsapp_phone}</p>
						</div>
					</div>
					<div className="sec2contactform">
						<h3 className="sec2frmtitle">Want to make Enquiry? Drop Us a Mail</h3>
                        <ContactForm />
                           
					</div>

				</div>

                <div>
                    <div className="sec2map">
                    <iframe src={"//maps.google.com/maps?q="+address!['latitude']+","+address!['longitude']+"&z=15&output=embed"}></iframe>
                    </div>
				</div>

                </div>
			</div>
	</div>
    </section>

<Footer />
</>
    )
}
}

export default Contact;