import React, { ReactNode } from "react";
import {NavLink, Link} from "react-router-dom";

class NotFound extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    message : '',
    };
}
render(): ReactNode {
    return(
<>
<section className="page_404">
	<div className="container container-404">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-sm-10 col-sm-offset-1  text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center ">404</h1>
		
		
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		Looks like you're lost.
		</h3>
		
		<p>The page you are looking for does not exists, but you can return back home!</p>
		
		<NavLink to="/" className="link_404">Go to Home</NavLink>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
</>
    )
}
}

export default NotFound;