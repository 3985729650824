import React, { useState, useEffect } from 'react';
import { name_short } from "../Constant";

interface Card {
  id: number;
  name: string;
  price: string;
  image: string;
}

interface CardApiProps {
  apiUrl: string;
}

const CardApi: React.FC<CardApiProps> = ({ apiUrl }) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data: { data: Card[] } = await response.json();

      setCards(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  return (
    <div className="gt-row col-4">
      {loading ? (
        <div className="loading-card">
          Loading Cards...
        </div>
      ) : (
        cards.map((card) => (
          <div key={card.id}>
            <p className="center">
              <img src={card.image} alt={card.name}/>
            </p>
            <h2>{name_short} {card.name}</h2>
            <h4>{card.price}</h4>
          </div>
        ))
      )}
    </div>
  );
};

export default CardApi;
