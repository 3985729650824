import $ from 'jquery';
import { currency } from '../Constant';

export const ScrollToTop = () : any => {
    $(function(){
    window.scrollTo(0, 0);
    $(document).find('body').scrollTop(0, 0);
    });
  }

  export const randomNum = (arr) => {
    return Math.floor(Math.random() * arr?.length);
  }

  export const toMoney = (amount, col = 2) => {
  return currency+""+formatMoney(amount, col, '.', ',');
  }

function formatMoney(number: number, decPlaces?: number, decSep?: string, thouSep?: string): string {
    decPlaces = isNaN(decPlaces || 0) ? 2 : decPlaces; //isNaN(decPlaces = Math.abs(decPlaces || 2))
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    const sign = number < 0 ? "-" : "";
    
    // Convert number to a string without decimal places
    const i = String(parseInt((number = Math.abs(Number(number) || 0)).toFixed(decPlaces)));
    
    // Declare j and calculate its value
    let j = i.length > 3 ? i.length % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thouSep}`) +
        (decPlaces ? decSep + Math.abs(number - parseInt(i)).toFixed(decPlaces).slice(2) : "");
}