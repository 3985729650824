import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import HeroIMG from "../Assets/nfc-card-phone.png";
import HeroIMG2 from "../Assets/alldashboard.png";
import { currency, name } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";

class Features extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}
render(): ReactNode {
    return(
<>
<Header classX="contain" title="Features"/>

<div className="grey-d-bg">
<div className="container">
<div className="list-ic-just-rw">

<h1>{name} Features</h1>

<h3>NFC functionality, Online & Offline QR codes, multi-layered protection, Instant connections via sharing.</h3>
<br/><br/>
<div className="gt-row col-4">

<div>
<div className="icon-ic">
<i className="fa fa-gamepad"></i>
</div>
<h2>Control Everything In One Place</h2>
<p>Creating and managing a digital business card has never been this easy! With Qliq Card, you can design digital business cards, manage them, and share them without stress - all from one dashboard.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-info-circle"></i>
</div>
<h2>Gain access to essential business information</h2>
<p>Organize all your digital business profile inside one dashboard. View and modify all essential business information. Get a visual representation of the devices, browsers, and platforms that viewed your cards, and so much more!</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-television"></i>
</div>
<h2>Monitor your business appointments</h2>
<p>With Qliq Card, keeping track of your appointments is now easier than ever. Get a graphical presentation of all your digital business cards. Also, view all the appointments booked with each one within the last 30 days.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-clock"></i>
</div>
<h2>Business Hours</h2>
<p>You can use the Business Hours feature to show your customers what hours you are open for business. You can set a start time and an end time, and begin working right away.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-handshake"></i>
</div>
<h2>Appointments</h2>
<p>Make your business flow smoothly by allowing clients book appointments with you. You can set up daily, weekly, or monthly appointments. Just set the date, allocate the time, and simplify your business transactions!</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-dashboard"></i>
</div>
<h2>Services</h2>
<p>Let your clients know that you mean business by showing them all the additional services you can provide for them.
Add the service title, describe it clearly, and get to work!</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-users"></i>
</div>
<h2>Testimonials</h2>
<p>Have you completed a lot of work in the past? Do you have awesome customer reviews to show off? No problem! Use the testimonials feature to display your reviews, as well as ratings from your previous clients.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-share-alt-square"></i>
</div>
<h2>Social Links</h2>
<p>Strengthen your social media presence and expand your social media connections. Link your favorite social media accounts -from Facebook, LinkedIn, and Twitter to YouTube, Instagram, Tiktok, and more!</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-calendar-alt"></i>
</div>
<h2>Calendar</h2>
<p>Never miss a task, project, or important deadline ever again! Get an overview of your daily, weekly, monthly, and yearly plans, as well as quick and easy reference notes.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-font"></i>
</div>
<h2>Custom Fonts</h2>
<p>You can choose a new font for your digital business card if you do not like the default one. The Custom Fonts Module gives you access to a variety of Google Fonts that you can use.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-lock"></i>
</div>
<h2>Password Protection</h2>
<p>Want to protect your business information from unwanted access? No problem! With the password protection feature, the digital business cards you send out can only be accessed by whoever you want, and no other.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-bullhorn"></i>
</div>
<h2>Mobility</h2>
<p>Expand the reach of your business! With Qliq Card, you can design a digital business card in minutes, and start sharing it with customers around the globe in the comfort of your home.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-id-card"></i>
</div>
<h2>Digital Business Card</h2>
<p>Share your contact information with your customers, without any 3rd party apps! Just tap your business card on the client's phone and your contact information appears instantly on the phone. </p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-phone-square"></i>
</div>
<h2>Call On Click</h2>
<p>Make it easy for your customers to contact you! With Qliq Card, your customers never have to scribble down your number again! They can just open your business card, tap the call icon, and contact you without hassle.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-window-restore"></i>
</div>
<h2>Design</h2>
<p>Customize your digital business card to match your brand. Choose from 20 beautiful themes and 5 unique colors! Edit your banner, logo, title, description, and more! - All in a few clicks.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-address-book"></i>
</div>
<h2>Save Contact</h2>
<p>Help your customers save your contact information quickly and easily. Save your customers the trouble of having to save your contact manually. They just tap the save icon on your business card and it's done.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-envelope"></i>
</div>
<h2>Email On Click</h2>
<p>Eliminate the need for your clients to remember your email address and manually input it into their mail. All they have to do is click on the mail icon on your digital business card.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-link"></i>
</div>
<h2>Website Link</h2>
<p>Make it easy for people to visit your website. Your digital business card allows users to access your website by clicking on the web icon. Now, they never have to manually input your URL into their browser.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-qrcode"></i>
</div>
<h2>QR Code card</h2>
<p>Share contact details wherever you are with your QR Code.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-video"></i>
</div>
<h2>Gallery</h2>
<p>Upload videos and pictures that best introduce you , your business, skills, and your experience.</p>
</div>


</div>  
</div>
</div>
</div>

<Footer />
</>
    )
}
}

export default Features;