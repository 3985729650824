import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import HeroIMG from "../Assets/nfc-card-phone.png";
import HeroIMG2 from "../Assets/alldashboard.png";
import { currency, name } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { toMoney } from "../Components/core";
import CardApi from "../Components/cardapi";
import PlanApi from "../Components/planapi";

class Index extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}
render(): ReactNode {
    return(
<>
<Header classX="contain" title="Plans & Pricing"/>

<section className="pricing-section">
        <div className="container">
            <div className="sec-title center">
                <span className="title">Our Plans</span>
                <br/>
                <div className="subtt text-primary">NFC business card + Free Basic plan = One time payment.</div>
                <div className="subtt">No monthly costs to use our dashboard.</div>
                <div className="subtt">Upgrade to Regular or Premium if you want to use more features and customizations.</div>
            </div>

            <div className="outer-box">
                <PlanApi />
            </div>
        </div>
    </section>

<Footer />
</>
    )
}
}

export default Index;