import React, { useState, useEffect } from 'react';

interface Banners {
  id: number;
  code: string;
  discount: string;
}

const Banner: React.FC = () => {
  const [banners, setBanner] = useState<Banners | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [isvalidated, setIsvalidated] = useState(false);

  useEffect(() => {
    // conditional logic here
    fetchBannerData();
  }, []); 
  
//Cyberpwince was here Lol Lol Lol
  const fetchBannerData = async () => {
    try {
      const response = await fetch('https://qliqc.com/api/getindexcoupon');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      const { id, code, discount } = responseData.data;
  
      if (id && code && discount) {
        // Update the banner state and set isvalidated to true
        setBanner({ id, code, discount });
        setIsvalidated(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  

  

  const handleCopyClick = () => {
    if (banners) {
      const textToCopy = banners.code;
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
    }
  };


  return isvalidated ? (
    <div className="banner">
      {banners && (
        <p>
          Get <span>{banners.discount}%</span> off your next Qliq card order with promo code{' '}
          <span className="code" onClick={handleCopyClick}>
            {banners.code}
          </span>{' '}
          <button className="btn" onClick={handleCopyClick}>
            <i className="fa fa-cut"></i> {isCopied ? 'Copied' : 'Copy'}
          </button>
        </p>
      )}
    </div>
  ) : null;
};

export default Banner;
