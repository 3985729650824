import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import HeroIMG from "../Assets/nfc-card-phone.png";
import HeroIMG2 from "../Assets/alldashboard.png";
import { currency, name, name_short, web_login_url } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import OnlinePlatformIMG from "../Assets/onlineplatform.png";
import productBlackCardIMG from "../Assets/blackcard.gif";
import productWhiteCardIMG from "../Assets/whitecard.gif";
import productGoldCardIMG from "../Assets/goldcard.gif";
import productBambooCardIMG from "../Assets/bamboocard.gif";
import productBlackMetalCardIMG from "../Assets/blackmetal.gif";
import productSilverMetalCardIMG from "../Assets/silvermetal.gif";
import productTagIMG from "../Assets/nfctag.gif";
import productWristBandIMG from "../Assets/wrist-band.gif";
import CardApi from "../Components/cardapi";

const ProductsList = [
{
image : productBlackCardIMG,
name : "Black PVC Card",
desc : "The Qliq Black PVC Card which is lovely and smart",
price : 18000
},
{
image : productWhiteCardIMG,
name : "White PVC Card",
desc : "The Qliq White PVC Card which is lovely and smart",
price : 18000
},
{
image : productGoldCardIMG,
name : "Gold PVC Card",
desc : "The Qliq Gold Card which is lovely and smart, Golden and Customazible",
price : 200000
},
{
image : productBambooCardIMG,
name : "Bamboo Card",
desc : "The Qliq Bamboo Card which is lovely and smart, Bamboom design and Customizable",
price : 22000
},
{
image : productBlackMetalCardIMG,
name : "Black Metal Card",
desc : "The Qliq Black Metal Card which is lovely and smart, Metal but Customazable",
price : 50000
},
{
image : productSilverMetalCardIMG,
name : "Silver Metal Card",
desc : "The Qliq Silver Metal Card which is lovely and smart, Metal but Customazable",
price : 50000
},
{
image : productTagIMG,
name : "NFC Tag",
desc : "The Qliq NFC TAG which is lovely and smart, water-proof and longer-proof",
price : 10000
},
{
image : productWristBandIMG,
name : "NFC Wrist-Band",
desc : "The Qliq NFC Wristband which is lovely with night reflection",
price : 13000
}
];

class Shop extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}
render(): ReactNode {
    return(
<>
<Header classX="contain" title="Shop :: NFC Products"/>


<div className="white-d-bg" id="short-plans">
<div className="container">
<div className="grid-3-for-shop">

<h1>{name} NFC products</h1>

<h3>Decide on what design suits you or your brand best and select from our available card options based on your preference.</h3>

<CardApi apiUrl="https://qliqc.com/api/getcardall" />

<div className="center"><br/>
<a href={web_login_url}>
<button className="btn">
<i className="fa fa-shopping-cart"></i> Login to Order
 </button>
</a>
</div>

</div>
</div>
</div>

<div className="grey-d-bg">
<div className="container">

<div className="list-ic-just-rw">
<div className="gt-row col-3">
<div>
<div className="icon-ic">
<i className="fa fa-gift"></i>
</div>
<h2>Free shipping</h2>
<p>Free delivery on all NFC business cards in Nigeria.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-certificate"></i>
</div>
<h2>100% warranty</h2>
<p>Replace your damaged card with a new one.</p>
</div>

<div>
<div className="icon-ic">
<i className="fa fa-dashboard"></i>
</div>
<h2>Free dashboard</h2>
<p>Use our online platform for free to update details.</p>
</div>

</div>  
</div>
</div>
</div>

<Footer />
</>
    )
}
}

export default Shop;