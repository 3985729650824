import React from 'react';
import {NavLink, Link} from "react-router-dom";
import LogoIcon from '../Assets/logo.png';
import {name, year, web_register_url, web_login_url } from "../Constant";
import MenuIcon from '../Assets/menu.png';
import Head from './head';
import $ from 'jquery';
import { ScrollToTop } from './core';
import Banner from './banner'; 

class Header extends React.Component <any> {

    constructor(props : any){
        super(props);
        
        this.state = {
          windowWidth: '',
          message: '',
        }
        
        }
       
        componentDidMount(){

          $(document, window).find('a').on('click', function(e){
            ScrollToTop();
            });

          $(function(){
          if(window.location.pathname?.split("/")[1] == '' && window.location.hash == ''){
            $('.home').addClass('active');
          }
          else{
            $('.home').removeClass('active');
          }

          $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
            /*if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
              $(this).addClass('active');
            }*/

            if($(this).attr('href')?.indexOf("#") > 0 && window.location.hash == ''){
              $(this).removeClass('active');
            }
          });

        });


        $(function(){
          $(document, window).on('click', '.nav-left-nav a, .mob-nav a', function(this: HTMLAnchorElement, e){
            if(window.location.hash != ''){
              $('.nav-left-nav a, .mob-nav a').removeClass('active');
            }

          $(this).addClass('active');
          if($(this).attr('href')?.indexOf("#") > 0 && window.location.hash == ''){
            $(this).removeClass('active');
          }

          if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash != ''){
            $(this).removeClass('active');
          }

          if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && $(this).hasClass('home')){
            $('.nav-left-nav a, .mob-nav a').removeClass('active');
            $(this).addClass('active');
          }
          });

          $(document, window).on('click', '.mob-nav a', function(this: HTMLAnchorElement, e){
            $('.mob-nav-overlay').addClass('hidden');
            $('body').removeClass('no-scroll');
          });
        });

        $(function(){
        $(document, window).scroll(function() {    
          var scroll = $(window).scrollTop();

          if (scroll >= 50) {
          $(".nav-header").addClass("scrolled");
          }
          else{
          $(".nav-header").removeClass("scrolled");
          }
        });
        });

        $(function(){
            $(document, window).on('click', '.hb-menu', function(e) {
              e.preventDefault();
                $('.mob-nav-overlay').removeClass('hidden');
                $('body').addClass('no-scroll');
           });

            $(document, window).on('click', '.mob-nav-exit-btn', function(e) {
            e.preventDefault();
            $('.mob-nav-overlay').addClass('hidden');
            $('body').removeClass('no-scroll');
         });
          });

        }

        componentWillUnmount(){
          
          }


render(){
return (
 <>
<Head title={this.props.title} />

<div className={this.props.classX}>
<header>

<div className="nav-header">
<Banner />
<div className="container">
<div className="nav-nav">
<div className="logo-wrap">
<NavLink to="/">
<div className="logo">
<img src={LogoIcon} alt={name} />
</div>
</NavLink>
</div>

    <div className="nav-links-nav">
    <div className="nav-left-nav">
    <NavLink to="/" className="home">
      Home
    </NavLink>

    <NavLink to="#how-it-works" href-target="#how-it-works">
      How it works
    </NavLink>

    <NavLink to="/shop">
      Shop
    </NavLink>

    <NavLink to="/pricing">
      Pricing
    </NavLink>

    <NavLink to="/reseller">
      Become a Reseller
    </NavLink>

    <NavLink to="/features">
      Features
    </NavLink>

    <NavLink to="/ambassador">
      Become an Ambassador
    </NavLink>

    <NavLink to="#faq" href-target="#faq">
      FAQ
    </NavLink>

    <NavLink to="/contact">
      Contact Us
    </NavLink>

    </div>

    <div className="nav-right-nav">
    
    <a href={web_login_url} className="signin-btn">
      <i className="fa fa-lock"></i>
      Login
    </a>


    <a href={web_register_url} className="signup-btn">
      Join for Free
    </a>
    
    </div>

    <div className="nb-sl"></div>
    
    <div className="hb-menu">
      <div className="hb-icon">
        <div className="bar-1"></div>
        <div className="bar-2"></div>
        <div className="bar-3"></div>
      </div>
    </div>
    </div>
   
    </div>
    </div>
    </div>

<div className="mob-nav-overlay hidden">
<div className="mob-nav-n">
<div className="mob-nav-exit">
<button className="mob-nav-exit-btn" title="Close">
  <i className="fa fa-times"></i>
</button>
</div>
<div className="mob-nav">
<NavLink to="/" className="home">
      Home
    </NavLink>

    <NavLink to="#how-it-works" href-target="#how-it-works">
      How it works
    </NavLink>

    <NavLink to="/shop">
      Shop
    </NavLink>

    <NavLink to="/pricing">
      Pricing
    </NavLink>

    <NavLink to="/reseller">
      Become a Reseller
    </NavLink>

    <NavLink to="/features">
      Features
    </NavLink>

    <NavLink to="/ambassador">
      Become an Ambassador
    </NavLink>

    <NavLink to="#faq" href-target="#faq">
      FAQ
    </NavLink>

    <NavLink to="/contact">
      Contact Us
    </NavLink>

    <div className="divider"></div>

    <a href={web_login_url} className="signin-btn">
      <i className="fa fa-lock hidden"></i>
      Login
    </a>


    <a href={web_register_url} className="signup-btn">
      Join for Free
    </a>
</div>
</div>
</div>
 
   <div className="header-body">
    <div className="container">
   {this.props.children}
   </div>
   </div>
   </header>
 </div>
  </>
)
}
}

export default Header;