import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { address, currency, email, name, phone, whatsapp_phone } from "../Constant";
import { NavLink } from "react-router-dom";
import $ from "jquery";

import ResellerPartner from "../Assets/undraw_collaborators.svg";
import { toMoney } from "../Components/core";
  
interface FormData {
    firstname: string;
    lastname: string;
    phone_number: string;
    email: string;
    date_of_birth: string;
    country: string;
    address: string;
    tiktok: string;
    instagram: string;
    youtube: string;
    twitter: string;
    message: string;
  }

const initialFormData = {
    firstname: '',
    lastname: '',
    phone_number: '',
    email: '',
    date_of_birth: '',
    country: '',
    address: '',
    tiktok: '',
    instagram: '',
    youtube: '',
    twitter: '',
    message: '',
  };
  
class Ambassador extends React.Component <any> {

constructor(props){
    super(props);
    this.state = {
    formData: initialFormData,
    isLoading: false,
    successMessage: '',
    error: '',
    message : '',
    };
}

componentDidMount(){
$(function(){
    $('.nav-left-nav a, .mob-nav a').each(function(this: HTMLAnchorElement){
        if($(this).attr('href')?.indexOf("#") < 1 && window.location.hash == '' && window.location.pathname?.split("/")[1] == $(this).attr('href')){
          //$(this).addClass('active');
        }
      });
});
}

setFormData = (name, value) => {
let prevFormData = this?.state!['formData'];
let newFormData = {
    ...prevFormData,
    [name]: value,
}

  this.setState({['formData']: newFormData});
}

setDefFormData = (defData) => {
  this.setState({['formData']: defData});
}

setSuccessMessage = (message) => {
    this.setState({['successMessage']: message});
}

setError = (message) => {
    this.setState({['error']: message});
}

setIsLoading = (message) => {
    this.setState({['isLoading']: message});
}

handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    this.setFormData(name, value);
};


handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setIsLoading(true);
    
    try {
      const response = await fetch('https://qliqc.com/api/ambassador', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this?.state!['formData']),
      });
     
      const data = await response.json();
      //console.log(data);
      if (data.status==true) {
        this.setSuccessMessage('Form submitted successfully!');
        this.setError('');
        this.setDefFormData(initialFormData);
        this.setIsLoading(false);
      } else {
        this.setError(data.message);
        this.setSuccessMessage('');
        this.setIsLoading(false);
      }
    } catch (error) {
        this.setError('An error occurred. Please try again later.');
        this.setSuccessMessage('');
        this.setIsLoading(false);
    }
  };

render(): ReactNode {
    return(
<>
<Header classX="contain" title="Become an Ambassador"/>

<section className="partner-section">

<div className="white-d-bg _min">
<div className="container">
<div className="grid-2-for-exp">

<div className="gt-row col-2">
<div className="exp-img-text">
<h1>Represent {name}</h1>
<p>Wondering what more to do with your large following on social media? Here’s a great chance to earn as you publicise {name}. Sign up as an Ambassador.
<br/><br/>
Being an Ambassador has never been easier. With these steps below, you are on your way to earning from {name} even as you become an important part of technologically inspired products that align with the future. 

<br/><h4>Ambassador Benefits:</h4>

<ul>
<li>Get Free Gifts</li>

<li>Get your unique code</li>

<li>Participate in live events</li>

<li>Get first dibs on new batches and products</li>

<li>Earn 15% from every card purchased with your code</li>
</ul>
<br/><br/>

<div>
<NavLink to="#become-ambassador" href-target="#become-ambassador" href-target-type="self">
<button className="pg-btn">
 Get Started <i className="fa fa-arrow-right hidden"></i>
 </button>
</NavLink>
</div>
</p>
</div>
<div className="exp-img-image">
<img src={ResellerPartner} alt="Image" />
</div>
</div>
</div>
</div>
			
	</div>
    </section>

<section className="contact-section" id="become-ambassador">
<div className="container">
<form onSubmit={this.handleSubmit}>	
			<div className="contact-form clearfix">

            <h1>Ready to Join?</h1>
            <h3>Fill out the application form below and start your journey with {name} today!</h3>

<div className="gt-row col-2" style={{gridGap: '30px'}}>

<div className="sec2contactform">
<h3 className="sec2frmtitle">Get started below</h3>

{this?.state!['error'] && <div style={{ color: 'red' }}>{this?.state!['error']}</div>}
      {this?.state!['successMessage'] && <div style={{ color: 'green' }}>{this?.state!['successMessage']}</div>}
<div className="clearfix">
<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="firstname" placeholder="First Name" value={this?.state!['formData'].firstname} onChange={this.handleChange} required/>
</div>
<input className="inp" type="text" name="lastname" placeholder="Last Name" value={this?.state!['formData'].lastname} onChange={this.handleChange} required/>
<div>
</div>
</div>

<div className="gt-row col-2">
<div>
<input  className="inp" type="Email" name="email" placeholder="Email Address" value={this?.state!['formData'].email} onChange={this.handleChange} required/>
</div>

<div>
<input className="inp" type="tel" name="phone_number" placeholder="Phone Number" value={this?.state!['formData'].phone_number} onChange={this.handleChange} required/>   
</div>
</div>

<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="country" placeholder="Country" value={this?.state!['formData'].country} onChange={this.handleChange} required/>   
</div>

<div>
<input className="inp" type="text" name="address" placeholder="Address" value={this?.state!['formData'].address} onChange={this.handleChange} required/>   
</div>
</div>

<div className="gt-row col">
<div>
    <br/>
<label>Date of Birth</label>
<input  className="inp" type="date" name="date_of_birth" placeholder="Date of Birth" value={this?.state!['formData'].date_of_birth} onChange={this.handleChange} required/>
</div>
</div>
    
</div>

</div>


<div className="sec2contactform">
    <div className="hideMobile" style={{marginTop: '15px'}}>
<br/>

    <h3 className="sec2frmtitle hiddens"></h3>
 </div>
    <div className="sec2innercont">
    
<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="tiktok" placeholder="TikTok Profile link" value={this?.state!['formData'].tiktok} onChange={this.handleChange} />
</div>
<input className="inp" type="text" name="instagram" placeholder="Instagram Profile link" value={this?.state!['formData'].instagram} onChange={this.handleChange} />
<div>
</div>
</div>

<div className="gt-row col-2">
<div>
<input className="inp" type="text" name="youtube" placeholder="Youtube Profile link" value={this?.state!['formData'].youtube} onChange={this.handleChange} />
</div>
<input className="inp" type="text" name="twitter" placeholder="Twitter Profile link" value={this?.state!['formData'].twitter} onChange={this.handleChange} />
<div>
</div>
</div>

<div className="clearfix pt0">
    <textarea name="message" id="" cols={30} rows={7} placeholder={"Why do you want to be a "+name+" Ambassador..."} onChange={this.handleChange} required>{this?.state!['formData'].message}</textarea>
</div>

</div>
   
</div>
</div>

<br/>
<div className="clearfix">
  
  
  <button className="btn" name="send_message" type="submit" disabled={this?.state!['isLoading']}>
        {this?.state!['isLoading'] ? (
          <span>
            Submiting... <i className="fa fa-spinner fa-spin"></i>
          </span>
        ) : (
          <span>Submit</span>
         
        )}
      </button>
  
  </div>
</div>
</form>
</div>
</section>

<Footer />
</>
    )
}
}

export default Ambassador;
